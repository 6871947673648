import React from 'react';
import { Helmet } from 'react-helmet';

const HubSpotCTAndMRIForm = ({ formId, heading }) => (
  <div>
    {heading && <h4>{heading}</h4>}
    <div id='hubSpotCTAndMRIForm'></div>
    <Helmet>
      <script
        type="text/javascript"
        src="//js.hsforms.net/forms/embed/v2.js"
        charset="utf-8"
        onLoad={`
          window.hbspt.forms.create({
            target: '#hubSpotCTAndMRIForm',
            portalId: "23801619",
            formId: "${formId}"
          });
        `}
      />
    </Helmet>
  </div>
);

export default HubSpotCTAndMRIForm;
